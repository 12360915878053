#calendar-page {
	position: absolute;
	top: 10vh;
	height: 120vh;
	background-color: #29292a;
	width: 100vw;
}

#calendar-container {
	padding-top: 3vh;	
	height: 80vh;
	width: 96vw;
	color: #fff;
	background-color: #29292a;
	padding-left: 2vw;
	padding-right: 2vw;
}

#calendar button:focus {
	outline: 0;
}

#calendar button:active {
	outline: 0;
}

#calendar-p a {
	color: #fff;
	text-decoration: underline;
}

#calendar-p a:visited {
	color: #fff;
}

#calendar-p a:hover {
	color: #61616B;
}

#calendar-p a:focus {
	color: #61616B;
}

#calendar-p a:active {
	color: #61616B;
}

#calendar-p {
	background-color: #29292a;
	margin: 0;
	padding-top: 2vw;
	padding-bottom: 3vw;
	padding-left: 7vw;
	padding-right: 7vw;
	font-size: 19px;
	line-height: 23px;
	color: #fff;
}

#room-list {
	background-color: #29292a;
	margin: 0;
	font-size: 19px;
	line-height: 23px;
	color: #fff;
	display: flex;
}

#room-list h5 {
	font-size: 20px;
	margin: 1vh;
	margin-top: 7vh;
}

#room-list div {
	flex: 1;
}

#room-type {
	width: 300px;
	/*flex: 1;*/
}