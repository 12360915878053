#amenities-image {
	background-image: url(/static/media/LizzyPro.bbb053eb.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 20% 40%;
	height: 100vh;
	color: #fff;
	background-color: #29292a;
}

#amenities-image h2 {
	margin: 0;
	padding: 0;
	position: absolute;
	bottom: 3vh;
	right: 3vw;
	font-size: 9vh;
	z-index: 1;
}

@media only screen and (min-width: 900px) {
	#amenities-image h2 {
		font-size: 10vh;
		right: 6vw;
	}
}

#amenities {
	color: #fff;
	background-color: #29292a;
	padding-top: 7vh;
}

#amenities p {
	background-color: #29292a;
	margin: 0;
	padding-left: 10vw;
	padding-right: 10vw;
	padding-top: 6vh;
	padding-bottom: 3vh;
	font-size: 17px;
	line-height: 18px;
}

#slideshow-container {
	width: 60vw;
	background-color: #29292a;
	margin-left: auto;
	margin-right: auto;
}

#slideshow-container img {
	width: 60vw;
	max-height: auto;
}

@media only screen and (min-width: 800px) {
	#amenities p {
		padding-left: 12vw;
		padding-right: 12vw;
		padding-top: 3vh;
	}

	#slideshow-container {
		width: 48vw;
	}

	#slideshow-container img {
		width: 48vw;
		max-height: auto;
	}
}
#beta-image {
	background-image: url(/static/media/NagypapaPro.459a9b01.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 60% 37%;
	height: 100vh;
	color: #fff;
	background-color: #29292a;
}

#madness-cave-photo2 {
	background-image: url(/static/media/madnessCave2Pro.e992f27b.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 12% 60%;
	height: 90vh;
	color: #fff;
	background-color: #29292a;
}

#drive-by {
	background-image: url(/static/media/driveByPro.995d051d.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 75% 0%;
	height: 90vh;
	color: #fff;
	background-color: #29292a;
}

#beta-image h2 {
	margin: 0;
	padding: 0;
	position: absolute;
	bottom: 3vh;
	left: 10vw;
	font-size: 11vh;
}

#beta h4 {
	margin: 0;
	color: #fff;
	background-color: #29292a;
	font-size: 28px;
	padding-top: 6vw;
}

#beta p {
	background-color: #29292a;
	margin: 0;
	padding-top: 1vw;
	padding-bottom: 3vw;
	padding-left: 7vw;
	padding-right: 7vw;
	font-size: 17px;
	line-height: 23px;
}

@media only screen and (min-width: 800px) {
	#beta p {
		padding-left: 12vw;
		padding-right: 12vw;
	}
}

#beta {
	color: #fff;
	background-color: #29292a;
}

#beta a {
	color: #fff;
	text-decoration: underline;
}

#beta a:visited {
	color: #fff;
}

#beta a:hover {
	color: #61616B;
}

#beta a:focus {
	color: #61616B;
}

#beta a:active {
	color: #61616B;
}
#calendar-page {
	position: absolute;
	top: 10vh;
	height: 120vh;
	background-color: #29292a;
	width: 100vw;
}

#calendar-container {
	padding-top: 3vh;	
	height: 80vh;
	width: 96vw;
	color: #fff;
	background-color: #29292a;
	padding-left: 2vw;
	padding-right: 2vw;
}

#calendar button:focus {
	outline: 0;
}

#calendar button:active {
	outline: 0;
}

#calendar-p a {
	color: #fff;
	text-decoration: underline;
}

#calendar-p a:visited {
	color: #fff;
}

#calendar-p a:hover {
	color: #61616B;
}

#calendar-p a:focus {
	color: #61616B;
}

#calendar-p a:active {
	color: #61616B;
}

#calendar-p {
	background-color: #29292a;
	margin: 0;
	padding-top: 2vw;
	padding-bottom: 3vw;
	padding-left: 7vw;
	padding-right: 7vw;
	font-size: 19px;
	line-height: 23px;
	color: #fff;
}

#room-list {
	background-color: #29292a;
	margin: 0;
	font-size: 19px;
	line-height: 23px;
	color: #fff;
	display: -webkit-flex;
	display: flex;
}

#room-list h5 {
	font-size: 20px;
	margin: 1vh;
	margin-top: 7vh;
}

#room-list div {
	-webkit-flex: 1 1;
	        flex: 1 1;
}

#room-type {
	width: 300px;
	/*flex: 1;*/
}
.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}
a{
  color: #fff;
}
a:visited{
  color: #fff;
}
a:focus{
  color: #000;
}
a:hover{
  color: #000;
}
a:active{
  color: #000;
}
button:focus {
  outline: 0;
}
button:active {
  outline: 0;
}
button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled].rbc-btn {
  cursor: not-allowed;
}
button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
          align-items: stretch;
}
.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}
.rbc-abs-full,
.rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rbc-rtl {
  direction: rtl;
}
.rbc-off-range {
  color: #999999;
}
.rbc-off-range-bg {
  background: #29292a;
}
.rbc-header {
  overflow: hidden;
  -webkit-flex: 1 0 0%;
          flex: 1 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid #DDD;
}
.rbc-header + .rbc-header {
  border-left: 1px solid #DDD;
}
.rbc-rtl .rbc-header + .rbc-header {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
.rbc-header > a,
.rbc-header > a:active,
.rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
}
.rbc-row-content {
  position: relative;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  z-index: 4;
}
.rbc-today {
  background-color: #61616B;
}
.rbc-toolbar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}
.rbc-toolbar .rbc-toolbar-label {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  padding: 0 10px;
  text-align: center;
}
.rbc-toolbar button {
  color: #fff;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #ccc;
  padding: .375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus {
  color: #373a3c;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.rbc-toolbar button:focus {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-toolbar button:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
}
.rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.rbc-btn-group button + button {
  margin-left: -1px;
}
.rbc-rtl .rbc-btn-group button + button {
  margin-left: 0;
  margin-right: -1px;
}
.rbc-btn-group + .rbc-btn-group,
.rbc-btn-group + button {
  margin-left: 10px;
}
.rbc-event {
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #3174ad;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
}
.rbc-slot-selecting .rbc-event {
  cursor: inherit;
  pointer-events: none;
}
/*.rbc-event.rbc-selected {
  background-color: #265985;
}*/
.rbc-event:focus {
  outline: 0;
}
.rbc-event-label {
  font-size: 80%;
}
.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}
.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.rbc-row-segment {
  padding: 0 1px 1px 1px;
}
.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1);
}
.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
  white-space: nowrap;
}
.rbc-month-view {
  position: relative;
  border: 1px solid #DDD;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 0 0;
          flex: 1 0;
  width: 100%;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  height: 100%;
}
.rbc-month-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.rbc-month-row {
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 0 0;
          flex: 1 0;
  -webkit-flex-basis: 0px;
          flex-basis: 0px;
  overflow: hidden;
  height: 100%;
}
.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #DDD;
}
.rbc-date-cell {
  -webkit-flex: 1 1 0;
          flex: 1 1;
  min-width: 0;
  padding-right: 5px;
  text-align: right;
}
.rbc-date-cell.rbc-now {
  font-weight: bold;
}
.rbc-date-cell > a,
.rbc-date-cell > a:active,
.rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
}
.rbc-row-bg {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex: 1 0 0;
          flex: 1 0;
  overflow: hidden;
}
.rbc-day-bg {
  -webkit-flex: 1 0 0%;
          flex: 1 0;
}
.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #DDD;

}
.rbc-rtl .rbc-day-bg + .rbc-day-bg {
  border-left-width: 0;
  border-right: 1px solid #DDD;

}
.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #fff;
  background-color: #61616B;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
}
.rbc-overlay > * + * {
  margin-top: 1px;
}
.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px;
}
.rbc-agenda-view {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 0 0;
          flex: 1 0;
  overflow: auto;
}
.rbc-agenda-view table.rbc-agenda-table {
  width: 100%;
  border: 1px solid #DDD;
  border-spacing: 0;
  border-collapse: collapse;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  padding: 5px 10px;
  vertical-align: top;
}
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  padding-left: 15px;
  padding-right: 15px;
  text-transform: lowercase;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid #DDD;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-top: 1px solid #DDD;
}
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  padding: 3px 5px;
  text-align: left;
  border-bottom: 1px solid #DDD;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: right;
}
.rbc-agenda-time-cell {
  text-transform: lowercase;
}
.rbc-agenda-time-cell .rbc-continues-after:after {
  content: ' \BB';
}
.rbc-agenda-time-cell .rbc-continues-prior:before {
  content: '\AB   ';
}
.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap;
}
.rbc-agenda-event-cell {
  width: 100%;
}
.rbc-time-column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 100%;
}
.rbc-time-column .rbc-timeslot-group {
  -webkit-flex: 1;
          flex: 1 1;
}
.rbc-timeslot-group {
  border-bottom: 1px solid #DDD;
  min-height: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
}
.rbc-time-gutter,
.rbc-header-gutter {
  -webkit-flex: none;
          flex: none;
}
.rbc-label {
  padding: 0 5px;
}
.rbc-day-slot {
  position: relative;
}
.rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  margin-right: 10px;
  top: 0;
}
.rbc-day-slot .rbc-events-container.rbc-is-rtl {
  left: 10px;
  right: 0;
}
.rbc-day-slot .rbc-event {
  border: 1px solid #265985;
  display: -webkit-flex;
  display: flex;
  max-height: 100%;
  min-height: 20px;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  overflow: hidden;
  position: absolute;
}
.rbc-day-slot .rbc-event-label {
  -webkit-flex: none;
          flex: none;
  padding-right: 5px;
  width: auto;
}
.rbc-day-slot .rbc-event-content {
  width: 100%;
  -webkit-flex: 1 1 0;
          flex: 1 1;
  word-wrap: break-word;
  line-height: 1;
  height: 100%;
  min-height: 1em;
}
.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #f7f7f7;
}
.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: white;
  border-right: 1px solid #DDD;
  z-index: 10;
  margin-right: -1px;
}
.rbc-time-view-resources .rbc-time-header {
  overflow: hidden;
}
.rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  -webkit-flex: 1 0 0;
          flex: 1 0;
  -webkit-flex-basis: 0px;
          flex-basis: 0px;
}
.rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none;
}
.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px;
}
.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  -webkit-flex: 1 1 0;
          flex: 1 1;
  -webkit-flex-basis: 0 px;
          flex-basis: 0 px;
}
.rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px;
}
.rbc-time-slot {
  -webkit-flex: 1 0 0;
          flex: 1 0;
}
.rbc-time-slot.rbc-now {
  font-weight: bold;
}
.rbc-day-header {
  text-align: center;
}
.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px;
}
.rbc-slot-selecting {
  cursor: move;
}
.rbc-time-view {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1;
          flex: 1 1;
  width: 100%;
  border: 1px solid #DDD;
  min-height: 0;
}
.rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
}
.rbc-time-view .rbc-allday-cell {
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  position: relative;
}
.rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
  border-left: 1px solid #DDD;
}
.rbc-time-view .rbc-allday-events {
  position: relative;
  z-index: 4;
}
.rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: 20px;
}
.rbc-time-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.rbc-time-header.rbc-overflowing {
  border-right: 1px solid #DDD;
}
.rbc-rtl .rbc-time-header.rbc-overflowing {
  border-right-width: 0;
  border-left: 1px solid #DDD;
}
.rbc-time-header > .rbc-row:first-child {
  border-bottom: 1px solid #DDD;
}
.rbc-time-header > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #DDD;
}
.rbc-time-header-cell-single-day {
  display: none;
}
.rbc-time-header-content {
  -webkit-flex: 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  min-width: 0;
  -webkit-flex-direction: column;
          flex-direction: column;
  border-left: 1px solid #DDD;
}
.rbc-rtl .rbc-time-header-content {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
.rbc-time-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 0 0%;
          flex: 1 0;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: 100%;
  border-top: 2px solid #DDD;
  overflow-y: auto;
  position: relative;
}
.rbc-time-content > .rbc-time-gutter {
  -webkit-flex: none;
          flex: none;
}
.rbc-time-content > * + * > * {
  border-left: 1px solid #DDD;
}
.rbc-rtl .rbc-time-content > * + * > * {
  border-left-width: 0;
  border-right: 1px solid #DDD;
}
.rbc-time-content > .rbc-day-slot {
  width: 100%;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
}
.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none;
}

#contact-image {
	background-image: url(/static/media/madnessCave1Pro.7858172c.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 12% 40%;
	height: 100vh;
	color: #fff;
	background-color: #29292a;
}

#contact-image h2 {
	margin: 0;
	padding: 0;
	position: absolute;
	bottom: 3vh;
	right: 3vw;
	font-size: 9vh;
}

#contact {
	height: 157vh;
	color: #fff;
	background-color: #29292a;
}

#contact .container {
	padding: 1vw;
	padding-top: 6vh;
	font-size: 19px;
}

#contact .icon-container {
	padding: 1vw;
}

#contact .text-container {
	padding: 1vw;
	padding-left: 20vw;
	padding-right: 20vw;
}

#contact a {
	color: #fff;
}

#contact a:visited {
	color: #fff;
}

#contact a:hover {
	color: #61616B;
}

#contact a:focus {
	color: #61616B;
}

#contact a:active {
	color: #61616B;
}

#contact i {
	font-size: 10vh;
}

#contact i:hover {
	color: #61616B;
}

#contact i:focus {
	color: #61616B;
}

#contact i:active {
	color: #61616B;
}

@media only screen and (min-height: 900px) {
	#contact-image h2 {
		font-size: 10vh;
		right: 6vw;
	}

	#contact {
		height: 140vh;
	}

	#contact .text-container {
		padding-left: 30vw;
		padding-right: 30vw;
	}
}

#map-image-container {
	background-color: #29292a;
}
#landing-image {
	background-image: url(/static/media/goldCoastLariPro.1177bb3f.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 80% 13%;
	height: 90vh;
	color: #fff;
	background-color: #29292a;
	position: relative;
	top: 10vh;
}

#tagline {
	color: #fff;
	font-size: 23px;
	position: absolute;
	bottom: 1vh;
	margin: 0;
	width: 100vw;
}
#navigation {
  background-color: #29292a;
  color: #fff;
  height: 10vh;
  width: 100%;
  position: fixed;
  z-index: 10;
}

#navigation button:focus {
  outline: 0;
}

#navigation h5:focus {
  outline: 0;
}

#navigation a:focus {
  outline: 0;
}

#navigation button:active {
  outline: 0;
}

#navigation h5:active {
  outline: 0;
}

#navigation a:active {
  outline: 0;
}

#navigation h3 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 10vh;
  width: 50vw;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2vh;
  font-size: 4vh;
}

#logo-container {
	height: 9vh;
	width: 9vh;
	padding: 1vh;
}

.bm-burger-button {
  position: fixed;
  width: 6vh;
  height: 6vh;
  right: 2vh;
  top: 2vh;
}

.bm-burger-bars {
  background: #fff;
}

.bm-burger-bars-hover {
  background: #61616B;
}

.bm-menu-wrap {
  position: fixed;
  top: 10vh;
}

.bm-menu {
  background: #29292a;
  font-size: 3vh;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.7);
  top: 10vh;
}

.bm-item {
  color: #fff;
  text-decoration: none;
}

.bm-item:hover {
  color: #61616B;
}

.bm-item:focus {
  color: #61616B;
}

.bm-item:active {
  color: #61616B;
}

@media only screen and (max-width: 750px) {
  #navigation h3 {
    padding-top: 0.5vh;
  }
}
.App {
  text-align: center;
}

.blur1 {
	background: linear-gradient(to top, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
	position: absolute;
	height: 23vh;
	width: 100vw;
  bottom: 0;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Alegreya Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

