.App {
  text-align: center;
}

.blur1 {
	background: linear-gradient(to top, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
	position: absolute;
	height: 23vh;
	width: 100vw;
  bottom: 0;
}