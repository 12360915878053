#landing-image {
	background-image: url("../../climbingPhotos/goldCoastLariPro.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 80% 13%;
	height: 90vh;
	color: #fff;
	background-color: #29292a;
	position: relative;
	top: 10vh;
}

#tagline {
	color: #fff;
	font-size: 23px;
	position: absolute;
	bottom: 1vh;
	margin: 0;
	width: 100vw;
}